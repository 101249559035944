import { createClient } from 'microcms-js-sdk'
import { CmsClient } from '.'
import { config } from 'config'
import { Faq } from 'types/faq'
import { News } from 'types/news'

type Category = {
  name?: string
  color?: string
}

type NewsModal = {
  id: string
  title: string
  content: string
  category?: Category
  hideList?: boolean
}

// const
const MAX_LIST_SIZE = 10 as const
const ENDPOINT = 'news' as const

// client
let client: ReturnType<typeof createClient> | undefined = undefined

export function getClient(): ReturnType<typeof createClient> {
  if (client) return client

  client = createClient({
    serviceDomain: 'manavi',
    apiKey: config.MICROCMS_API_KEY,
  })
  return client
}

export class MicroCmsClient implements CmsClient {
  public async fetchNewses(page?: number): Promise<{ items: News[]; total: number }> {
    if (!client) {
      throw new Error('Must initialize a client before calling this function')
    }

    const queries = {
      limit: MAX_LIST_SIZE,
      offset: MAX_LIST_SIZE * ((page || 1) - 1),
      filters: 'hideList[not_equals]true',
      orders: '-publishedAt',
    }

    const result = await client.getList<NewsModal>({
      endpoint: ENDPOINT,
      queries,
    })

    const items = result.contents.map((news) => ({
      id: news.id,
      title: news.title,
      content: news.content,
      publishedAt: news.publishedAt,
      category: {
        name: news.category?.name || '',
        color: news.category?.color || '',
      },
      hideList: news.hideList,
    }))

    return { items, total: result.totalCount }
  }

  public async fetchNews(id: string): Promise<News> {
    if (!client) {
      throw new Error('Must initialize a client before calling this function')
    }

    const result = await client.getListDetail<NewsModal>({
      endpoint: ENDPOINT,
      contentId: id,
    })

    return {
      id: result.id,
      title: result.title,
      content: result.content,
      publishedAt: result.publishedAt,
      category: {
        name: result.category?.name || '',
        color: result.category?.color || '',
      },
      hideList: result.hideList,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public fetchFaqs(page?: number | undefined): Promise<{ items: Faq[]; total: number }> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public fetchFaq(id: string): Promise<Faq> {
    throw new Error('Method not implemented.')
  }
}
