import { localCmsClient } from './localCmsClient'
import { MicroCmsClient } from './microCmsClient'
import { NewtClient } from './newtClient'
import { config } from 'config'
import { Faq } from 'types/faq'
import { News } from 'types/news'

export interface CmsClient {
  fetchNewses(
    page?: number,
    isDmNotice?: boolean,
    categoryList?: string[]
  ): Promise<{ items: Array<News>; total: number }>
  fetchNews(id: string, isDmNotice?: boolean): Promise<News>
  fetchFaqs(page?: number): Promise<{ items: Array<Faq>; total: number }>
  fetchFaq(id: string): Promise<Faq>
}

export function getClient(): CmsClient {
  if (config.ENABLE_NEWT) {
    return new NewtClient()
  }

  if (config.ENABLE_MICROCMS) {
    return new MicroCmsClient()
  }

  return new localCmsClient()
}
