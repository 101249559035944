import { matchingNoticeDetail } from 'public/locales/ja/components/notices/noticeDetail'

type Category = {
  id: string
  name: string
  color: string
  createdAt?: string
  updatedAt?: string
}

type News = {
  id: string
  publishedAt: string
  title: string
  content: string
  category: Category
  createdAt?: string
  updatedAt?: string
}

const categories: { [key: string]: Category } = {
  service: {
    id: 'service',
    name: 'サービス',
    color: '#FEC8D0',
    createdAt: '',
    updatedAt: '',
  },
  pr: {
    id: 'pr',
    name: 'PR',
    color: '#C9E4FE',
    createdAt: '',
    updatedAt: '',
  },
  corporate: {
    id: 'corporate',
    name: 'コーポレート',
    color: '#DCDEE8',
    createdAt: '',
    updatedAt: '',
  },
  improvement: {
    id: 'improvement',
    name: '機能改善',
    color: '#D3F2EB',
    createdAt: '',
    updatedAt: '',
  },
}

export const getNoticeList = (): News[] => {
  return [
    {
      id: '17',
      publishedAt: '2021-06-23T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice17.title,
      content: matchingNoticeDetail.matchingNoticeList.notice17.description,
      category: categories.service,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '16',
      publishedAt: '2021-03-15T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice16.title,
      content: matchingNoticeDetail.matchingNoticeList.notice16.description,
      category: categories.pr,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '15',
      publishedAt: '2021-03-10T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice15.title,
      content: matchingNoticeDetail.matchingNoticeList.notice15.description,
      category: categories.corporate,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '14',
      publishedAt: '2020-06-18T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice14.title,
      content: matchingNoticeDetail.matchingNoticeList.notice14.description,
      category: categories.improvement,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '13',
      publishedAt: '2020-06-09T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice13.title,
      content: matchingNoticeDetail.matchingNoticeList.notice13.description,
      category: categories.service,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '12',
      publishedAt: '2020-05-11T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice12.title,
      content: matchingNoticeDetail.matchingNoticeList.notice12.description,
      category: categories.pr,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '11',
      publishedAt: '2020-05-01T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice11.title,
      content: matchingNoticeDetail.matchingNoticeList.notice11.description,
      category: categories.corporate,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '10',
      publishedAt: '2020-04-24T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice10.title,
      content: matchingNoticeDetail.matchingNoticeList.notice10.description,
      category: categories.improvement,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '9',
      publishedAt: '2020-03-10T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice9.title,
      content: matchingNoticeDetail.matchingNoticeList.notice9.description,
      category: categories.service,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '8',
      publishedAt: '2020-02-28T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice8.title,
      content: matchingNoticeDetail.matchingNoticeList.notice8.description,
      category: categories.pr,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '7',
      publishedAt: '2020-02-26T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice7.title,
      content: matchingNoticeDetail.matchingNoticeList.notice7.description,
      category: categories.corporate,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '6',
      publishedAt: '2020-01-06T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice6.title,
      content: matchingNoticeDetail.matchingNoticeList.notice6.description,
      category: categories.improvement,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '5',
      publishedAt: '2019-09-17T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice5.title,
      content: matchingNoticeDetail.matchingNoticeList.notice5.description,
      category: categories.notice,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '4',
      publishedAt: '2019-06-06T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice4.title,
      content: matchingNoticeDetail.matchingNoticeList.notice4.description,
      category: categories.notice,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '3',
      publishedAt: '2018-12-07T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice3.title,
      content: matchingNoticeDetail.matchingNoticeList.notice3.description,
      category: categories.notice,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '2',
      publishedAt: '2018-06-20T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice2.title,
      content: matchingNoticeDetail.matchingNoticeList.notice2.description,
      category: categories.notice,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: '1',
      publishedAt: '2018-06-01T00:00:00Z',
      title: matchingNoticeDetail.matchingNoticeList.notice1.title,
      content: matchingNoticeDetail.matchingNoticeList.notice1.description,
      category: categories.notice,
      createdAt: '',
      updatedAt: '',
    },
  ]
}
