import { matchingNoticeList } from './noticeList'

export const matchingNoticeDetail = {
  matchingNoticeList,
  title: '【M&Aナビ】M&A・事業承継マッチングプラットフォーム',
  description: 'のお知らせです。M&Aナビは、中小企業に特化したM&A・事業承継のマッチングプラットフォームです。',
  heading: '新着情報',
  breadcrumb: {
    top: 'トップ',
    notice: ' 新着情報',
  },
  keywords: 'M&A,事業承継,M&A マッチング,M&A プラットフォーム,会社 売却',
}
