import { createClient, GetContentsQuery, Content } from 'newt-client-js'
import { CmsClient } from '.'
import { config } from 'config'
import { Faq } from 'types/faq'
import { News } from 'types/news'

// const
const MAX_LIST_SIZE = 10 as const

// microCMSからnewtに移行対応
// 一部microCMSのIDをnewtのIDに置き換える
const MICROCMS_ID_MAP = {
  ecksa7uva37: '635a2919066bbfadd183a72e', // しんだんフォーム
  d3g6ytds934d: '635a29df066bbfadd183d055', // しんだんフォーム(西中国信金事例)
  p2eenfgdftvn: '635a2ac3066bbfadd18413d4', // Findy Team+ Award
  pem757buyr: '635a2afb066bbfadd1843a19', // 有料化
} as const

type NewsModel = {
  title: string
  content: string
  postDate: string
  category: NewsCategoryModel & Content
  hideList: boolean
} & Content
type NewsCategoryModel = {
  name: string
  color: string
}
type FaqModel = {
  id: string
  question: string
  answer: string
  category: FaqCategoryModel
} & Content
type FaqCategoryModel = {
  categoryName: string
}

let client: ReturnType<typeof createClient> | undefined = undefined
if (config.ENABLE_NEWT) {
  client = createClient({
    spaceUid: config.NEWT_SETTING.SPACE_UID,
    token: config.NEWT_SETTING.TOKEN,
    apiType: 'cdn',
    retryOnError: true,
    retryLimit: 3,
  })
}

export class NewtClient implements CmsClient {
  public async fetchNewses(
    page?: number,
    isDmNotice?: boolean,
    categoryList?: string[]
  ): Promise<{ items: News[]; total: number }> {
    if (!client) {
      throw new Error('Initialize before calling this function')
    }

    const query: GetContentsQuery = {
      hideList: false,
      limit: MAX_LIST_SIZE,
      skip: MAX_LIST_SIZE * ((page || 1) - 1),
      order: ['-_sys.customOrder'],
    }

    if (categoryList && categoryList?.length > 0) {
      query.or = [{ category: { in: categoryList } }]
    }

    const result = await client.getContents<NewsModel>({
      appUid: isDmNotice ? config.NEWT_SETTING.DM_NOTICES.APP_UID : config.NEWT_SETTING.NOTICES.APP_UID,
      modelUid: isDmNotice ? config.NEWT_SETTING.DM_NOTICES.MODEL.NEWS : config.NEWT_SETTING.NOTICES.MODEL.NEWS,
      query,
    })

    const items = result.items.map((item) => ({
      id: item._id,
      title: item.title,
      content: item.content,
      publishedAt: item.postDate,
      category: {
        name: item.category.name || '',
        color: item.category.color || '',
      },
      hideList: item.hideList,
    }))

    return { items, total: result.total }
  }

  public fetchNews<News>(id: string, isDmNotice?: boolean): Promise<News> {
    if (!client) {
      throw new Error('Initialize before calling this function')
    }

    const contentId = Object.keys(MICROCMS_ID_MAP).includes(id)
      ? MICROCMS_ID_MAP[id as keyof typeof MICROCMS_ID_MAP]
      : id

    return client.getContent<News>({
      appUid: isDmNotice ? config.NEWT_SETTING.DM_NOTICES.APP_UID : config.NEWT_SETTING.NOTICES.APP_UID,
      modelUid: isDmNotice ? config.NEWT_SETTING.DM_NOTICES.MODEL.NEWS : config.NEWT_SETTING.NOTICES.MODEL.NEWS,
      contentId,
    })
  }

  public async fetchFaqs(page?: number): Promise<{ items: Faq[]; total: number }> {
    if (!client) {
      throw new Error('Initialize before calling this function')
    }

    const query: GetContentsQuery = {
      limit: MAX_LIST_SIZE,
      skip: MAX_LIST_SIZE * ((page || 1) - 1),
    }

    const result = await client.getContents<FaqModel>({
      appUid: config.NEWT_SETTING.FAQ.APP_UID,
      modelUid: config.NEWT_SETTING.FAQ.MODEL.FAQ,
      query,
    })

    const items: Faq[] = result.items.map((item) => ({
      id: item._id,
      question: item.question,
      answer: item.answer,
      category: item.category.categoryName,
    }))

    return { items, total: result.total }
  }

  public fetchFaq(id: string): Promise<Faq> {
    if (!client) {
      throw new Error('Initialize before calling this function')
    }

    return client.getContent<Faq>({
      appUid: config.NEWT_SETTING.FAQ.APP_UID,
      modelUid: config.NEWT_SETTING.FAQ.MODEL.FAQ,
      contentId: id,
    })
  }
}
